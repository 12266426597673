<template>
  <v-app id="inspire">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md8>
            <v-card class="elevation-4">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Login </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    name="login"
                    label="Coach Email"
                    v-model="email"
                    @change="email = email.toLowerCase()"
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    id="password"
                    name="password"
                    label="Password"
                    :type="passwordType"
                    v-model="password"
                  ></v-text-field>
                  <v-btn @click="togglePasswordVisible()"> SHOW / HIDE </v-btn>
                </v-form>
              </v-card-text>
              <v-snackbar v-model="snackbar" :multi-line="multiLine">
                {{ text }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="primary"
                    outlined
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
              <v-card-actions>
                <v-checkbox v-model="rememberMe" label="Remember Me" />
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="forgotPassword()"
                  >Forgot Password</v-btn
                >
                <v-btn color="primary" @click="doLogin()">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions({
      attemptLogin: "attemptLogin",
    }),
    doLogin() {
      if (this.rememberMe) {
        this.storeEmail();
      }
      this.attemptLogin({ email: this.email, password: this.password })
        .then(() => {
          this.$router.push("/app");
        })
        .catch(() => {
          this.snackbar = true;
        });
    },
    forgotPassword() {
      //TODO: These should be Snackbars
      if (this.email && this.email != "") {
        alert("email sent ;)");
      } else {
        alert("Please Enter A Valid Email Address And Try Again.");
      }
    },
    storeEmail() {
      window.localStorage.setItem("loginEmail", this.email);
    },

    async retrieveEmail() {
      this.email = window.localStorage.getItem("loginEmail");
    },
    togglePasswordVisible() {
      this.passwordType = this.passwordType == "password" ? "text" : "password";
    },
  },
  data: () => ({
    email: "",
    password: "",
    passwordType: "",
    rememberMe: false,
    snackbar: false,
    multiLine: true,
    showPassord: false,
    text: `Invalid email and/or password.`,
  }),
  mounted() {
    this.retrieveEmail();
    if (
      this.$route.query &&
      this.$route.query.email &&
      this.$route.query.password
    ) {
      const { email } = this.$route.query;
      this.email = email;

      this.doLogin();
    }
  },
};
</script>
